import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import axios from "axios";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import { ForkApi } from "@/api/Fork";
import { LoadingAnimation } from "@/components/ui/LoadingAnimation";
import { LoadingButton } from "@/components/ui/LoadingButton";
import { useDebounce } from "@/hooks/useDebounce";
import { useFetch } from "@/hooks/useFetch";
import { Experiment } from "@/model/api";
import { ExperimentNamespace, NamespaceToLabel } from "@/model/namespace";

export const HomeComponent = () => {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(true);
  const { inProgress } = useMsal();
  const [filteredExpData, setFilteredExpData] = useState<Experiment[]>([]);
  const [searchFilterInput, setSearchFilterInput] = useState("");
  const searchFilter = useDebounce(searchFilterInput, 100);

  const {
    loading: expLoading,
    data: expData,
  }: {
    data: Experiment[];
    error: Error | undefined;
    loading: boolean;
  } = useFetch(ForkApi.Experiments, {}, [], true, axios.get);

  useEffect(() => {
    if (expLoading || !expData) {
      return;
    }

    let filterRes = expData;
    if (searchFilter.length > 0) {
      filterRes = expData.filter((x) =>
        x.experiment_name.includes(searchFilter)
      );
    }

    setFilteredExpData(filterRes);
  }, [expLoading, expData, searchFilter]);

  useEffect(() => {
    if (!expLoading && inProgress == InteractionStatus.None) {
      setIsLoading(false);
    }
  }, [expLoading, inProgress]);

  return (
    <div className="absolute inset-0">
      <div className="absolute inset-x-4 top-0 h-16 flex items-center justify-between gap-4">
        <div className="text-2xl font-bold">Experiments</div>
        <div className="flex gap-2 w-full max-w-[500px] items-center">
          <div className="text-lg font-bold">Search</div>
          <input
            type="text"
            name="experiment_search_input"
            value={searchFilterInput}
            className="w-full p-1 border border-gray-400 rounded outline-none text-start disabled:bg-gray-200"
            onChange={(event) =>
              setSearchFilterInput(event.currentTarget.value)
            }
          />
          <div className="w-4" />
          <LoadingButton
            isLoading={isLoading}
            className="grow px-3 whitespace-nowrap border border-slate-800 h-8 rounded-lg font-bold text-slate-800 hover:text-slate-600 hover:bg-slate-200"
            onClick={() => router.push("/experiment/new")}
          >
            New Experiment
          </LoadingButton>
        </div>
      </div>
      <div className="absolute inset-x-4 bottom-4 top-16">
        {isLoading ? (
          <LoadingAnimation />
        ) : (
          <table className="w-full text-left">
            <thead>
              <tr className="border border-slate-500">
                <th className="p-2 select-text">Namespace</th>
                <th className="p-2 select-text">Name</th>
                <th className="w-20"></th>
              </tr>
            </thead>
            <tbody>
              {filteredExpData?.map((exp, i) => {
                return (
                  <tr
                    className="border border-slate-500"
                    key={`exp-cohorts-${i}`}
                  >
                    <td className="p-2 select-text">
                      {NamespaceToLabel(
                        exp.experiment_namespace as ExperimentNamespace
                      )}
                    </td>
                    <td className="p-2 select-text">{exp.experiment_name}</td>
                    <td className="p-2 text-center">
                      <LoadingButton
                        isLoading={isLoading}
                        className="border border-slate-800 w-16 h-8 rounded-lg font-bold text-slate-800 hover:text-slate-600 hover:bg-slate-200"
                        onClick={() =>
                          router.push(`/experiment/edit/${exp.id}`)
                        }
                      >
                        Edit
                      </LoadingButton>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};
