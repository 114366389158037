import { MouseEventHandler } from "react";

import { Loading } from "./Loading";

interface Props {
  children: JSX.Element[] | JSX.Element | string;
  className?: string;
  dataTestId?: string;
  disabled?: boolean;
  isLoading: boolean;
  isSecondary?: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
}

export const LoadingButton = ({
  isLoading,
  disabled = false,
  children,
  onClick,
  isSecondary = false,
  className = "h-11",
  dataTestId,
}: Props) => (
  <button
    className={`${
      isSecondary ? "button-secondary" : "button"
    } text-center px-0 ${className}`}
    onClick={onClick}
    disabled={disabled || isLoading}
    data-testid={dataTestId}
  >
    {isLoading ? <Loading /> : children}
  </button>
);
