import { HomeComponent } from "@/components/home/HomeComponent";
import { ServicePage } from "@/model/page";
import { BaseUrl } from "@/util/app";

const Home: ServicePage = () => <HomeComponent />;

Home.getMetadata = () => ({
  pageDescription: () => "A/B Experiment Manager and Manifest Generator",
  pageTitle: () => "Fork - Home",
  pageUrl: () => BaseUrl,
});

export default Home;
